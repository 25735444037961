<template>
    <div :id="blok._uid"
        class="md:mx-md 2xl:mx-0">
        <div class="mb-sm flex justify-center">
            <p class="font-prenton-cond text-4xl font-bold uppercase md:text-8xl">{{ blok.title }}</p>
        </div>

        <div class="col-span-full grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6">
            <div v-for="(content, index) in blok.content"
                :key="index"
                class="flex h-full items-center justify-center">
                <v-storyblok-component :blok="content"
                    class="items-center" />
            </div>
        </div>

        <div class="mt-xl flex justify-center md:mt-xl">
            <nuxt-link :to="url"
                :aria-label="blok.link_title">
                <p class="font-sans underline decoration-solid hover:no-underline">{{ blok.link_title }}</p>
            </nuxt-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-brands-grid' | 'sb-brands-grid-brand' }>
}>();

const { format: formatSbUrl } = useSbUrl();
const url = computed(() => formatSbUrl(properties.blok.link?.cached_url));
</script>
